import React from 'react';
import profileImage from './profile.jpg';

class ProfileImage extends React.Component {
    render() {
        return(
            <img  data-aos="fade-up" data-aos-once={true} data-aos-duration={800} data-aos-offset={50} className="about__profile-img" style={{ width: this.props.width}} src={profileImage} alt="Stanlee profile"/>
        );
    }
}

export default ProfileImage;