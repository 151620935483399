import React from 'react';
import Header from './header/Header';
import About from './about/About';
import Nav from './nav/Nav';
import Work from './work/Work';
import Footer from './footer/Footer';

class App extends React.Component {
    renderDots = () => {
        return <div className="dots"></div>;
    }

    renderNavigationBar = () => {
        if(window.innerWidth > 700) {
            return <Nav />;
        }
        return null;
    }

    render() {
        return(
            <div className="container">
                { this.renderNavigationBar() }
                <Header />
                <About />
                { this.renderDots() }
                <Work />
                { this.renderDots() }
                <Footer />
            </div>
        );
    }
}

export default App;