import React from 'react';
import profileImage from '../shared/profile.jpg';

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isZero: true,
      previousScrollPosition: window.pageYOffset,
      displayName: false
    };

    this.navRef = React.createRef();
  }

  handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;

    if (currentScrollPosition === 0) {
      this.setState({ isZero: true, displayName: false });
    } else if (
      this.state.previousScrollPosition > currentScrollPosition &&
      currentScrollPosition !== 0
    ) {
      this.navRef.current.style.top = '0';
      this.setState({ isZero: false, displayName: true });
    } else {
      this.navRef.current.style.top = '-80px';
    }
    this.setState({ previousScrollPosition: currentScrollPosition });
    // if (window.pageYOffset === 0) {
    //   this.setState({ isZero: true });
    // } else {
    //   this.setState({ isZero: false });
    // }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <div
        ref={this.navRef}
        className="navbar"
        style={
          this.state.isZero
            ? { backgroundColor: 'transparent', boxShadow: 'none' }
            : { backgroundColor: 'white' }
        }
      >
        <a
          href="#"
          onClick={this.onProfileClick}
          className="navbar__profile"
          style={
            this.state.displayName
              ? { opacity: 1, display: 'flex' }
              : { opacity: 0, display: 'none' }
          }
        >
          <img
            className="navbar__img"
            src={profileImage}
            alt="Stanlee Profile"
          />
          <h2 className="navbar__name">Stanlee Mok</h2>
        </a>
        <ul className="navbar__list">
          <a href="#1"><li className="navbar__item">
            About
          </li></a>
          <a href="#2"><li className="navbar__item">
            Timeline
          </li></a>
          <a href="#3"><li className="navbar__item">
            Contact
          </li></a>
        </ul>
      </div>
    );
  }
}

export default Nav;
