import React from 'react';
import Particles from 'react-particles-js';
import ProfileImage from '../shared/ProfileImage';

class About extends React.Component {
    renderBreak = () => {
      if(window.innerWidth < 550) {
        return(
          <span>
            <br /><br />
          </span>
        );
      }
    }

    renderParticles() {
      const particlesOptions = {
        particles: {
          number: {
            value: 10,
            density: {
              enable: true,
              value_area: 1000
            }
          },
          opacity: {
            anim: {
                speed: .5,
                opacity_min: 0.1,
            }
          },
          color: {
              value: ['#fdd562', '#FDC830', '#f8b802', '#f58955', '#F37335']
          },
          move: {
              speed: 1,
              bounce: false
          },
          size: {
              value: 30,
              random: true
          }
        }
      }

      if(window.innerWidth > 700) {
        return <Particles className='particles' params={particlesOptions} />;
      }
      return null;
    }

    render() {
        return(
            <section id="1" className="section-about">
                {this.renderParticles()}
                <div className="about">
                    <ProfileImage width="13.2rem" />
                    <h3 className="heading-primary mb-md" data-aos="fade-up" data-aos-once={true} data-aos-duration={800} data-aos-offset={135}>Hi!&nbsp;</h3>
                    <p className="paragraph mb4ab" data-aos="fade-up" data-aos-once={true} data-aos-duration={800} data-aos-offset={135}>I'm a front-end web developer based in Kuala Lumpur, Malaysia that found myself passionate about web technologies and latest trends.</p>
                    <div className="dots mb4ab"></div>
                    <h3 className="heading-primary mb-md" data-aos="fade-up" data-aos-once={true} data-aos-duration={800} data-aos-offset={135}>A little bit more about me&nbsp;</h3>
                    <p className="paragraph" data-aos="fade-up" data-aos-once={true} data-aos-duration={800} data-aos-offset={135}>I was graduated on 2018 with a Bachelor's Degree in Statistics. Undeniably, I loved what I learned in those good old days. I mean Statistics can be really fun aside of bunch of boring digits, when you're able to visualize the data and listen to the message they convey. But with the growing amount of data, manually computing just doesn't work, we need some kind of tools to handle them - <i>programming</i>.&nbsp;
                    
                    {this.renderBreak()}
                    
                    I started learning coding in 22 and was really excited about it. <strong>Code makes me happy</strong>. Didn't you feel the same when you looking at those monospaced font, well indented code? During the time, I was also very interested in figuring out how the web works, so I started spending my spare time demystifying it. And here I am, a front-end developer, enthusiastic about what I do as there are so much more to learn. I can't stop and I won't stop <i>learning</i>.</p>
                </div>
            </section>
        )
    }
}

export default About;