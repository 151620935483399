import React from 'react';
import { ReactComponent as Github } from './Github.svg';
import { ReactComponent as Email } from './mail.svg';
import { ReactComponent as Linkedin } from './Linkedin.svg';

class Footer extends React.Component {
  state = {
      content: 'Copy Email',
      clicked: false
  }

  onEmailLogoClick = () => {
    // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
    // you will not see the textarea, as it is added and removed within the same synchronous invocation of Javascript code.
    const textarea = document.createElement('textarea');
    textarea.textContent = 'me@stanleemok.com';
    textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
        return document.execCommand("copy");  // Security exception may be thrown by some browsers.
    } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
    } finally {
        document.body.removeChild(textarea);
    }
  };

  onEmailBoxClick = () => {
    this.setState({
        content: 'Copied!',
        clicked: true
    })
  }

  onEmailBoxLeave = () => {
    this.setState({
        content: 'Copy Email',
        clicked: false
    })
  }

  render() {
    return (
      <div id="3" className="footer">
        <h3 className="heading-primary mb-lg">Follow me&nbsp;</h3>
        <div className="footer__logos mb-sm">   
          
          <a
            href="https://github.com/stanlee94"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Github className="footer__logo footer__logo--github" />
          </a>

          <a
            href="https://www.linkedin.com/in/stanleemok/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin className="footer__logo footer__logo--linkedin" />
          </a>

          <span className="footer__email-box" onClick={this.onEmailBoxClick} onMouseLeave={this.onEmailBoxLeave}>
            <Email onClick={this.onEmailLogoClick} className="footer__logo footer__logo--email" />
            <span className="footer__tooltip" style={{ backgroundColor: this.state.clicked ? '#72e5a5' : '#bbb' }}>{this.state.content}</span>
          </span>
          
        </div>
        <p className="footer__email"><span role="img" aria-label="email emoji" aria-labelledby="footer__email">📧:&nbsp;</span>me@stanleemok.com</p>
        <p className="footer__cheers">Santé!</p>
      </div>
    );
  }
}

export default Footer;
