import React from 'react';
import Typed from 'typed.js';

class Header extends React.Component { 
    componentDidMount() {      
        let strings;  
        if(window.innerWidth < 400) {
            strings = ['Stanlee']
        } else if(window.innerWidth < 700) {
            strings = ['Stanlee<br>Mok^800', 'Web<br>Developer^800', 'Mobile<br>Developer^800', 'Stanlee<br>Mko', 'Stanlee<br>Mok'];
        } else {
            strings = ['Stanlee Mok^800', 'Web Developer^800', 'Mobile Developer^800', 'Stanlee Mko', 'Stanlee Mok'];
        }

        const options = {
            strings,
            typeSpeed: 100,
            backSpeed: 50,
            startDelay: 2000,
            backDelay: 0
        };
        this.typed = new Typed(this.el, options);
    }

    render() {
        return(
            <header className="header">
                <h3 className="heading-primary mb-sm">I'm</h3>
                <h1 ref={(el) => { this.el = el }} className="header__title"></h1>                
            </header>
        );
    }
}

export default Header;