import React from 'react';
import petronasLogo from './petronasLogo.png';
import upm from './upm.png';
import bizitLogo from './bizit_logo.png';

class Work extends React.Component {
    render() {
        return(
            <section id="2" className="work">
                <h3 className="heading-primary mb-lg">Timeline&nbsp;</h3>
                <div className="work__container mb-sm">
                    
                    <div className="work__logo-container work__logo-container--petronas">
                        <img className="work__logo" src={petronasLogo} alt="Petronas Logo"/>
                    </div>
                    <div className="work__content work__content--petronas" data-aos="fade-up" data-aos-once={true} data-aos-duration={600} data-aos-offset={300}>
                        <div className="work__company work__company--petronas"></div>
                        <div className="work__info">
                            <div className="work__org">PETRONAS ICT Sdn Bhd</div>
                            <div className="work__title mb-md">Software Developer</div>
                            
                            <br />Stanlee is a Front-End Developer working in Digital Delivery team since August 2018. He is currently working on shipping internal products using React and React Native, handling Self Service Business Inteligence Integration, promoting data culture in workplace, creating technical API documentation, managing collaboration tools - Confluence and Azure DevOps, adhering to Scrum and Agile practices during development, working in Microsoft Azure and Amazon Web Services Cloud Platform. </div>
                    </div>

                    <div className="work__logo-container work__logo-container--upm">
                        <img className="work__logo" src={upm} alt="UPM Logo"/>
                    </div>
                    <div className="work__content work__content--upm" data-aos="fade-up" data-aos-once={true} data-aos-duration={600} data-aos-offset={150}>
                        <div className="work__company work__company--upm"></div>
                        <div className="work__info">
                            <div className="work__org work__org--upm">Universiti Putra Malaysia</div>
                            <div className="work__title work__title--upm mb-md">Tutor</div>

                            <br />Stanlee was a Statistics tutor and graduated on June 2018. He enjoyed sharing his knowledge with others, taking part in Statistics related conference and presenting his research idea to public. He was experienced in data modelling by adopting his statistical knowledge - Multiple Regression, Experimental Design, Principal Component, Time Series to real-world data using R programming, Minitab and SAS.</div>
                    </div>
                    
                    <div className="work__logo-container work__logo-container--bizit">
                        <img className="work__logo" src={bizitLogo} alt="Bizit Systems Logo"/>
                        <div className="work__last">Born</div>
                    </div>
                    <div className="work__content work__content--bizit" data-aos="fade-up" data-aos-once={true} data-aos-duration={600} data-aos-offset={250}>
                        <div className="work__company work__company--bizit"></div>
                        <div className="work__info">
                            <div className="work__org">Bizit Systems Sdn Bhd</div>
                            <div className="work__title mb-md">Intern</div>
                            
                            <br />Stanlee was an intern working on offering quality improvement, statistical solutions and technical supports to organizations. He worked closely with the industry and provided timely feedback on their production data using various Quality Controls tools - Cause-and-effect diagram, Control Charts and Pareto Chart. Being able to involve in the industry and apply what he learned was a great experience and invaluable to him. </div>
                    </div>
                </div>
                <div className="work__funny">Born</div>
            </section>
        );
    }
}

export default Work;